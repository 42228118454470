import { Box, Typography } from '@mui/material';
import React from 'react';

const style = {
  container: {
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '1px',
    width: 'fit-content',
  },
};

type Props = {
  text: string;
  color: 'active' | 'disabled' | 'success';
  size?: 'large' | 'small';
};

const StatusChip: React.FC<Props> = ({ text, color, size = 'small' }) => {
  const getHtmlColor = () => {
    switch (color) {
      case 'active':
        return '#2196F3';
      case 'success':
        return '#2E8B57';
      default:
        return '#979FA8';
    }
  };

  const getVariant = () => {
    if (size === 'small') {
      return 'buttonSmall';
    }
    return 'buttonMedium';
  };

  const getPadding = () => {
    if (size === 'small') {
      return '2px 5px 3px';
    }
    return '5px 8px 6px';
  };

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      display="flex"
      sx={[style.container, { borderColor: getHtmlColor(), padding: getPadding() }]}
    >
      <Typography textTransform="uppercase" letterSpacing={0} color={getHtmlColor()} variant={getVariant()}>
        {text}
      </Typography>
    </Box>
  );
};

export default StatusChip;
