import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import routes from '../../routes';

const RequireUnauth: React.FC = ({ children }) => {
  const { user } = useAuth();
  const location = useLocation();
  const { state }: { state: any } = useLocation();

  return user ? (
    <Navigate to={state?.path || routes.quotations.path} replace state={{ path: location.pathname }} />
  ) : (
    <>{children}</>
  );
};

export default RequireUnauth;
