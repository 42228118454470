import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@mui/icons-material';
import { IconButton, TablePagination, TablePaginationProps, Tooltip, Typography, useTheme } from '@mui/material';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import theme, { sxHover } from '../../style/theme';

const style = {
  pagination: {
    borderTop: '1px solid ' + theme.palette.grey[200],
    '& button': {
      color: theme.palette.grey[400],
    },
  },
};

type Props = {
  namespace: string;
} & TablePaginationProps;

const DashboardTablePagination: React.FC<Props> = (props) => {
  const { namespace } = props;
  const { palette } = useTheme();
  const { t } = useTranslation();

  const renderPageActions = (actionProps: TablePaginationActionsProps) => {
    const {
      className,
      count,
      page,
      onPageChange,
      getItemAriaLabel,
      rowsPerPage,
      backIconButtonProps,
      nextIconButtonProps,
    } = actionProps;
    const lastPage = Math.ceil(count / rowsPerPage) - 1;
    const disableNext = nextIconButtonProps?.disabled || page >= lastPage;
    const disablePrev = backIconButtonProps?.disabled || page === 0;

    return (
      <div className={className}>
        <Tooltip title={getItemAriaLabel('first')}>
          <span>
            <IconButton sx={sxHover} onClick={(e) => onPageChange(e, 0)} disabled={disablePrev}>
              <FirstPage />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={getItemAriaLabel('previous')}>
          <span>
            <IconButton sx={sxHover} onClick={(e) => onPageChange(e, page - 1)} disabled={disablePrev}>
              <KeyboardArrowLeft />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={getItemAriaLabel('next')}>
          <span>
            <IconButton sx={sxHover} onClick={(e) => onPageChange(e, page + 1)} disabled={disableNext}>
              <KeyboardArrowRight />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={getItemAriaLabel('last')}>
          <span>
            <IconButton sx={sxHover} onClick={(e) => onPageChange(e, lastPage)} disabled={disableNext}>
              <LastPage />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    );
  };

  return (
    <TablePagination
      component="div"
      sx={style.pagination}
      labelDisplayedRows={(pagination) => (
        <Typography color={palette.grey[400]} variant="paragraphSmall">
          {t(`${namespace}:list.pagination`, { pagination })}
        </Typography>
      )}
      ActionsComponent={renderPageActions}
      getItemAriaLabel={(type: string) => t(`common:pagination.${type}`)}
      rowsPerPageOptions={[]}
      {...props}
    />
  );
};

export default DashboardTablePagination;
