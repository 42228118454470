import { Add } from '@mui/icons-material';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { territories as territoriesApi } from '../../api';
import { CreationContext } from '../../contexts/CreationContext';
import { CreationForm, Domain, Territories, UserPlants } from '../../types';
import { decodeString, getDomain } from '../../utils/helper';
import DatePickerHookForm from '../FormControls/HookForm/DatePickerHookForm';
import InputHookForm from '../FormControls/HookForm/InputHookForm';
import SearchSelectHookForm from '../FormControls/HookForm/SearchSelectHookForm';
import SelectHookForm from '../FormControls/HookForm/SelectHookForm';
import InputErrorMessage from '../FormControls/InputErrorMessage';
import AttachmentsDropZone from './Attachments/AttachmentsDropZone';
import SelectQuotation from './SelectQuotation';

const style = {
  container: {
    marginBottom: 15,
  },
  addReferenceButton: {
    marginTop: '22px',
    width: '350px',
  },
  referenceInputWidth: {
    sm: '250px',
    xs: '100%',
  },
  requiredArrivalDate: {
    width: '200px',
  },
  divider: {
    marginTop: '40px',
  },
};

type Props = {
  userPlants: UserPlants['plants'];
  onPlantChanged: (plantId: string) => void;
};

const CreationStep1 = ({ userPlants, onPlantChanged }: Props) => {
  const { t } = useTranslation();
  const context = useContext(CreationContext);
  const { control, setValue, watch, getValues } = useFormContext<CreationForm>();
  const { errors } = useFormState<CreationForm>();
  const [watchPlant, watchAttachments, watchQuotation, watchRequiredArrivalDate] = watch(['plant', 'attachments', 'quotationId', 'requiredArrivalDate']);
  const [territories, setTerritories] = useState<Territories['territories']>([]);
  const [countReference, setCountReference] = useState(0);

  useEffect(() => {
    fetchTerritories(watchPlant);
    onPlantChanged(watchPlant);
  }, [onPlantChanged, watchPlant]);

  useEffect(() => {
    let count = 0;
    if (getValues('reference2')) {
      count = 1;
    }
    if (getValues('reference3')) {
      count = 2;
    }
    setCountReference(count);
  }, [watchQuotation, getValues]);

  const getPlantLabel = () =>
    context.type === 'Quotation'
      ? t('quotations:creation.step1.selectPlant')
      : t('sales-orders:creation.step1.selectPlant');

  const fetchTerritories = async (plantId: string) => {
    if (plantId) {
      const result = await territoriesApi.getTerritories(plantId);
      setTerritories(result.data.territories);
    } else {
      setTerritories([]);
    }
  };

  const onAttachmentsChange = (files: File[]) => setValue('attachments', files, { shouldValidate: true });

  const refreshCustomerAccount = (plantId: string) => {
    const accounts = userPlants.find((p) => p.id === plantId)?.customerAccounts ?? [];
    const accountId = accounts.length === 1 ? accounts[0].id : '';
    setValue('customerAccountId', accountId, { shouldValidate: !!accountId });
  };

  const renderReferenceField = (name: string) => (
    <InputHookForm
      control={control}
      inputWidth={style.referenceInputWidth}
      label={t(`form:step1.${name}`)}
      name={name}
      maxLength={255}
    />
  );

  const renderReferences = () => (
    <Box>
      {renderReferenceField('reference1')}
      {countReference > 0 && renderReferenceField('reference2')}
      {countReference > 1 && renderReferenceField('reference3')}
      <Button
        startIcon={<Add />}
        sx={style.addReferenceButton}
        disabled={countReference > 1}
        variant="outlined"
        onClick={() => setCountReference((p) => p + 1)}
      >
        {t('form:step1.addReference')}
      </Button>
    </Box>
  );

  const renderWishedLeadTime = () => (
    <Stack spacing={0.7}>
      <InputHookForm
        control={control}
        label={t('form:step1.wishLeadTime')}
        type="number"
        name="wishLeadTime"
        inputWidth={150}
        min={0}
      />
      <Typography fontStyle="italic" variant="caption">
        {t('form:step1.wishLeadTimeHelp')}
      </Typography>
    </Stack>
  );

  const renderAttachments = () => (
    <Box>
      <AttachmentsDropZone files={watchAttachments ?? []} onChange={onAttachmentsChange} />
      <InputErrorMessage errors={errors} name="attachments" />
    </Box>
  );

  const requiredArrivalDateMinDate = new Date().setDate(new Date().getDate() + 1);

  return (
    <Box sx={style.container}>
      {context.type === 'SalesOrder' && <SelectQuotation userPlants={userPlants} />}
      <Stack spacing={1}>
        {getDomain() !== Domain.firetrol && (
          <SelectHookForm
            label={getPlantLabel()}
            name="plant"
            control={control}
            inputWidth={300}
            disabled={!!context.quotation}
            data={userPlants.map((u) => ({ label: u.name, value: u.id }))}
            onChange={refreshCustomerAccount}
          />
        )}
        <SelectHookForm
          inputWidth={300}
          label={t('form:step1.selectCustomerAccount')}
          name="customerAccountId"
          control={control}
          disabled={!!context.quotation}
          data={userPlants
            .find(({ id }) => id === watchPlant)
            ?.customerAccounts.map((u) => ({ label: u.name, value: u.id }))}
        />
        <SearchSelectHookForm
          label={t('form:step1.territory')}
          options={territories.map((c) => ({ label: decodeString(`${c.code} - ${c.description}`), value: c.id }))}
          control={control}
          name="territoryId"
          inputWidth={400}
          disabled={!!context.quotation}
        />
      </Stack>
      <Divider sx={style.divider} />
      <Stack spacing={1}>
        {context.type === 'SalesOrder' && (
          <InputHookForm label={t('form:step1.poNumber')} control={control} inputWidth={200} name="poNumber" />
        )}
        {context.type === 'Quotation' && (
          <InputHookForm label={t('form:step1.rfq')} inputWidth={200} control={control} name="rfq" />
        )}
        {renderReferences()}
        {context.type === 'SalesOrder' && (
          <DatePickerHookForm
            control={control}
            defaultValue={watchRequiredArrivalDate}
            inputFormat="yyyy-MM-dd"
            name="requiredArrivalDate"
            mask="____-__-__"
            minDate={requiredArrivalDateMinDate}
            disabled={!!context.quotation}
            inputProps={{
              label: t('form:step1.requiredArrivalDate'),
              placeholder: 'YYYY-MM-DD',
              inputWidth: 170,
            }}
          />
        )}
        {context.type === 'Quotation' && renderWishedLeadTime()}
        {context.type === 'SalesOrder' && renderAttachments()}
      </Stack>
    </Box>
  );
};
export default CreationStep1;
