import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

type Props = {
  label: string | React.ReactElement;
  name: string;
  control: Control<any, any>;
};

const CheckboxHookForm: React.FC<Props> = ({ label, name, control }: Props) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label={label} />}
  />
);

export default CheckboxHookForm;
