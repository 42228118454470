import { Grid } from '@mui/material';
import React from 'react';
import theme from '../style/theme';

const style = {
  container: {
    flexGrow: 1,
    background: `radial-gradient(100% 62.57% at 0% 100%, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
  },
  childContainer: {
    backgroundColor: theme.palette.common.white,
    padding: 8,
  },
};

const UnauthenticatedBox: React.FC = ({ children }) => (
  <Grid container sx={style.container}>
    <Grid item sx={style.childContainer} xs={12} md={7} lg={5}>
      {children}
    </Grid>
  </Grid>
);

export default UnauthenticatedBox;
