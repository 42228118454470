import { CheckCircle } from '@mui/icons-material';
import { Box, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchSalesOrder } from '../../types';
import { formatCurrency } from '../../utils/helper';
import SalesOrderStatus from './SalesOrderStatus';

type Props = {
  salesOrder: SearchSalesOrder;
  isSelected: boolean;
};

const SalesOrderListItem: React.FC<Props> = ({ salesOrder, isSelected }) => {
  const { soNumber, status, alert, companyName, isAcknowledged, total, poNumber } = salesOrder;
  const { palette } = useTheme();
  const { t } = useTranslation();

  const renderAcknowledge = () => (
    <Tooltip title={t(`sales-orders:list.isAcknowledge`) as string}>
      <CheckCircle htmlColor={palette.success.main} />
    </Tooltip>
  );

  return (
    <Stack width="100%">
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <Typography
            color={isSelected ? 'primary' : palette.grey[500]}
            fontWeight={isSelected ? 700 : 500}
            variant="paragraphSmall"
          >
            {soNumber}
          </Typography>
          <SalesOrderStatus alert={alert} status={status} />
        </Stack>
        {isAcknowledged && renderAcknowledge()}
      </Box>
      <Typography variant="caption">{companyName}</Typography>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography color={palette.grey[400]} variant="caption">
          {poNumber}
        </Typography>
        <Typography variant="paragraphSmall">{formatCurrency(total)}</Typography>
      </Box>
    </Stack>
  );
};

export default SalesOrderListItem;
