import { Stack } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, isValid, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import React, { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { LanguageCode } from '../../../types';
import { getCurrentLanguage } from '../../../utils/helper';
import Input from '../Input';
import InputErrorMessage from '../InputErrorMessage';

type Props = {
  defaultValue?: string,
  inputProps: Omit<React.ComponentProps<typeof Input>, 'variant'>;
  name: string;
  control: Control<any, any>;
  hideError?: boolean;
  inputWidth?: number;
} & Omit<React.ComponentProps<typeof DatePicker>, 'value' | 'onChange' | 'renderInput'>;

const DatePickerHookForm: React.FC<Props> = ({
  defaultValue,
  name,
  control,
  hideError,
  inputProps,
  ...props
}: Props) => {
  const [date, setDate] = useState<Date | null>(defaultValue ? parseISO(defaultValue) : null);
  return <Controller
    control={control}
    name={name}
    render={({ field: { onChange }, formState: { errors } }) => {
      return <Stack id={name} width="100%">
        <LocalizationProvider adapterLocale={getCurrentLanguage() === LanguageCode.fr ? fr : undefined} dateAdapter={AdapterDateFns}>
          <DatePicker
            {...props}
            onChange={(date) => {
              if (isValid(date)) {
                onChange(format(date as Date, 'yyyy-MM-dd'));
              }
              setDate(date as Date);
            }}
            value={date}
            renderInput={({
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              error, label, variant,
              ...params
            }: Record<string, any>) =>
              <Input
                variant="outlinedWhite"
                name={name}
                onChange={event => {
                  onChange(event.target.value);
                  if (isValid(parseISO(event.target.value))) {
                    setDate(parseISO(event.target.value));
                  }
                }}
                {...inputProps}
                {...params}
              />
            }
          />
          <InputErrorMessage hideError={hideError} errors={errors} name={name} />
        </LocalizationProvider>
      </Stack>
    }}
  />
};

export default DatePickerHookForm;
