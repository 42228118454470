import { ErrorMessage } from '@hookform/error-message';
import { GlobalStyles, Typography } from '@mui/material';
import React from 'react';
import theme from '../../style/theme';

type Props = {
  errors: any;
  name: string;
  hideError?: boolean;
};

const InputErrorMessage: React.FC<Props> = ({ errors, name, hideError }: Props) => (
  <ErrorMessage
    errors={errors}
    name={name}
    render={({ message }) => (
      <>
        <GlobalStyles
          styles={{
            [`form div[id='${name}'] fieldset`]: {
              borderColor: theme.palette.error.main,
            },
          }}
        />
        {!hideError && <Typography variant="inputError">{message}</Typography>}
      </>
    )}
  />
);

export default InputErrorMessage;
