import { SvgIconComponent } from '@mui/icons-material';
import { Box, Stack, SxProps, Theme, Typography, useTheme } from '@mui/material';
import React from 'react';
import theme from '../style/theme';
import { isString } from '../utils/helper';

const style = {
  container: {
    height: '100%',
  },
  icon: {
    fontSize: 70,
    color: theme.palette.grey[300],
  },
};

type Props = {
  title: string | React.ReactElement;
  description?: string;
  icon?: SvgIconComponent;
  containerSx?: SxProps<Theme>;
};

const IconTextPlaceholder: React.FC<Props> = ({ title, description, icon, containerSx }) => {
  const { palette } = useTheme();

  const renderTitle = () => {
    if (isString(title)) {
      return <Typography variant="h3">{title}</Typography>;
    }

    return <Box>{title}</Box>;
  };

  return (
    <Stack
      sx={{ ...style.container, ...containerSx }}
      color={palette.grey[500]}
      display="flex"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      {!!icon && React.createElement(icon, { style: style.icon })}
      {renderTitle()}
      {!!description && <Typography variant="paragraphBig">{description}</Typography>}
    </Stack>
  );
};

export default IconTextPlaceholder;
