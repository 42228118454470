import { Print } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { documents as documentsApi } from '../../api';
import theme, { sxHover } from '../../style/theme';
import { DocumentType, OrderStatus, SalesOrder } from '../../types';
import DashboardItemDetails from '../Dashboard/DashboardItemDetails';
import SalesOrderStatus from './SalesOrderStatus';

const style = {
  printButton: {
    marginRight: 2,
    color: theme.palette.grey[400],
    ...sxHover,
  },
  header: {
    padding: '0px 20px 30px 20px',
  },
};

type Props = {
  salesOrder: SalesOrder;
};

const SalesOrderHeader: React.FC<Props> = ({ salesOrder }) => {
  const { details } = salesOrder;
  const { t } = useTranslation();
  const [isDownloading, setDownloading] = useState(false);

  const getHeaderData = () => [
    {
      title: t('sales-orders:details.poNumber'),
      value: details.poNumber,
      gridSize: 2,
    },
    {
      title: t('sales-orders:details.references'),
      value: details.references,
      gridSize: 4.5,
    },
    {
      title: t('sales-orders:details.shipToAddress'),
      value: details.shipToAddress,
      gridSize: 2,
    },
    {
      title: t('common:status'),
      value: <SalesOrderStatus size="large" alert={details.alert} status={details.status} />,
      gridSize: 1.5,
    },
    {
      title: t('sales-orders:details.type'),
      value: details.coverage,
      gridSize: 1,
    },
    {
      title: t('sales-orders:details.currency'),
      value: details.currency,
      gridSize: 1,
    },
  ];

  const onPrintPress = async () => {
    setDownloading(true);
    try {
      await documentsApi.download(details.id, DocumentType.SalesOrder);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <Stack spacing={5} sx={style.header}>
      <Stack direction="row" display="flex" justifyContent="space-between">
        <Typography variant="h1">{t('sales-orders:details.title', { number: details.soNumber })}</Typography>
        {details.status !== OrderStatus.Draft && (
          <LoadingButton loading={isDownloading} sx={style.printButton} startIcon={<Print />} onClick={onPrintPress}>
            <Typography variant="buttonMedium">{t('common:print')}</Typography>
          </LoadingButton>
        )}
      </Stack>
      <Box>
        <DashboardItemDetails data={getHeaderData()}></DashboardItemDetails>
      </Box>
    </Stack>
  );
};

export default SalesOrderHeader;
