import { Close } from '@mui/icons-material';
import { Box, Button, Container, Drawer, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useAppBarHeight from '../hooks/useAppBarHeight';
import { sxHover } from '../style/theme';
import { isString } from '../utils/helper';

const style = {
  bottomDrawerContainer: {
    marginLeft: '25px',
    marginRight: '25px',
    borderRadius: '16px 16px 0 0',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)',
    height: '75%',
  },
  rightDrawerContainer: {
    width: {
      xs: '100%',
      sm: '500px',
    },
  },
};

type Props = {
  title: string | React.ReactElement;
  onClose: () => void;
  isOpen: boolean;
  anchor?: 'right' | 'bottom';
};

const BaseDrawer: React.FC<Props> = (props) => {
  const { children, onClose, title, isOpen, anchor = 'right' } = props;
  const { t } = useTranslation();
  const appBarHeight = useAppBarHeight();

  const renderTitle = () => {
    if (anchor === 'right') {
      return (
        <Container sx={{ paddingTop: `${appBarHeight + 20}px` }}>
          <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent="space-between">
            {isString(title) ? <Typography variant="h2">{title}</Typography> : title}
            <Button sx={sxHover} variant="text" onClick={onClose} startIcon={<Close fontSize="large" />}>
              {t('common:close')}
            </Button>
          </Box>
        </Container>
      );
    }
    return isString(title) ? <Typography variant="h2">{title}</Typography> : title;
  };

  return (
    <Drawer
      disableEnforceFocus
      PaperProps={{ sx: anchor === 'right' ? style.rightDrawerContainer : style.bottomDrawerContainer }}
      anchor={anchor}
      open={isOpen}
      onClose={onClose}
    >
      {renderTitle()}
      <Container>{children}</Container>
    </Drawer>
  );
};

export default BaseDrawer;
