import { Box, CircularProgress, Divider } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { salesOrders as salesOrdersApi, user } from '../api';
import {
  DashboardBox,
  DashboardSearch,
  IconTextPlaceholder,
  SalesOrderHeader,
  SalesOrderLineDetails,
  SalesOrderListItem,
  SalesOrderShipments,
} from '../components';
import routes from '../routes';
import { BaseDashboardSearchQuery, OrderStatus, SalesOrder, SearchSalesOrder } from '../types';
import Analytics from '../utils/analytics';
import { LIMIT_SALES_ORDERS } from '../utils/constants';
import PaymentButton from '../components/Payment/PaymentButton';

export function SalesOrders() {
  const { t } = useTranslation();
  const [salesOrders, setSalesOrders] = useState<SearchSalesOrder[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [currentSalesOrder, setCurrentSalesOrder] = useState<SalesOrder | null>(null);
  const currentParamId = useRef<string | null>(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [userCCAuthorized, setUserCCAuthorized] = useState(false);

  const searchSalesOrders = async (query: BaseDashboardSearchQuery) => {
    setSalesOrders([]);
    if (query.resetPagination) {
      setTotalCount(0);
    }
    const { data } = await salesOrdersApi.search(LIMIT_SALES_ORDERS, query.offset, query.keywords, query.status);
    setSalesOrders(data.data);
    setTotalCount(data.meta.totalCount);
  };

  useEffect(() => {
    (async () => {
      if (id) {
        let data: SalesOrder | null = null;
        try {
          setLoading(true);
          setCurrentSalesOrder(null);
          data = (await salesOrdersApi.get(id)).data;
        } finally {
          if (!currentParamId.current || currentParamId.current === id) {
            setCurrentSalesOrder(data);
            currentParamId.current = null;
            const results = (await user.getPlants()).data.plants;
            if (data) {
              results.forEach((plant) => {
                const cust = plant.customerAccounts.find((customer) => {
                  return customer.id === data?.details.customerAccountId;
                });
                if (cust?.ccAuthorized && cust.stripeAccountId !== '') {
                  setUserCCAuthorized(cust.ccAuthorized);
                }
              });
            }
            setLoading(false);
          }
        }
      }
    })();
  }, [id]);

  const onSalesOrderClick = ({ id }: { id: string }) => {
    Analytics.sendEvent('click_sales_order', { sales_order_no: id });
    currentParamId.current = id;
    navigate(routes.salesOrder.path.replace(':id', id), { replace: true });
  };

  const renderSalesOrderItem = (salesOrder: SearchSalesOrder, isSelected: boolean) => (
    <SalesOrderListItem salesOrder={salesOrder} isSelected={isSelected} />
  );

  const renderDashboardSearch = () => {
    return (
      <DashboardSearch
        namespace="sales-orders"
        renderItem={renderSalesOrderItem}
        isSelected={(salesOrder) => salesOrder.id === id}
        data={salesOrders}
        total={totalCount}
        limit={LIMIT_SALES_ORDERS}
        onItemClick={onSalesOrderClick}
        statuses={Object.values(OrderStatus)}
        refresh={searchSalesOrders}
        createHref={routes.createSalesOrder.path}
      />
    );
  };

  const renderDashboardContent = () => {
    if (isLoading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      );
    } else if (currentSalesOrder?.details) {
      return (
        <Box>
          <SalesOrderHeader salesOrder={currentSalesOrder} />
          <Divider />
          <SalesOrderLineDetails salesOrder={currentSalesOrder} />
          <PaymentButton userCCAuthorized={userCCAuthorized} currentSalesOrder={currentSalesOrder} />
          <SalesOrderShipments salesOrder={currentSalesOrder} />
        </Box>
      );
    } else if (id) {
      return <IconTextPlaceholder title={t('sales-orders:details.notFound', { id })} />;
    } else {
      return (
        <IconTextPlaceholder
          title={t('sales-orders:details.noSelectedTitle')}
          description={t('sales-orders:details.noSelectedDescription')}
        />
      );
    }
  };

  return <DashboardBox leftComponent={renderDashboardSearch()} rightComponent={renderDashboardContent()} />;
}
