import React from 'react';
import * as Yup from 'yup';

export type WizardStepProps = {
  validationSchema: Yup.AnyObjectSchema;
  title: string;
};

const WizardStep: React.FC<WizardStepProps> = ({ children }) => <>{children}</>;
export default WizardStep;
