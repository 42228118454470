import { Warning } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React from 'react';

type Props = {
  alertText: string | null;
  size: 'large' | 'medium' | 'small';
};

const AlertIcon: React.FC<Props> = ({ alertText, size }) => {
  if (!alertText) {
    return null;
  }
  return (
    <Tooltip title={alertText}>
      <Warning fontSize={size} color="warning" />
    </Tooltip>
  );
};

export default AlertIcon;
