import { Box, TextField, TextFieldProps, Typography, TypographyProps } from '@mui/material';
import React, { Ref } from 'react';
import theme from '../../style/theme';

const style = {
  container: {
    paddingTop: 3,
    width: '100%',
  },
  label: {
    marginBottom: '12px',
  },
};

type Props = {
  label?: string;
  variant?: 'outlined' | 'outlinedWhite';
  labelVariant?: TypographyProps['variant'];
  textAlign?: CanvasTextAlign;
  inputWidth?: any;
  maxLength?: number;
  containerRef?: Ref<HTMLElement>;
} & Omit<TextFieldProps, 'variant'>;

const Input: React.FC<Props> = (props) => {
  const {
    label,
    variant,
    labelVariant,
    textAlign = 'start',
    inputWidth,
    maxLength,
    InputProps,
    containerRef,
    ...cleanProps
  } = props;

  const backgroundColor = variant === 'outlinedWhite' ? theme.palette.common.white : theme.palette.grey[100];

  return (
    <Box sx={{ ...style.container }}>
      {!!label && (
        <Typography display="block" sx={style.label} variant={labelVariant ?? 'label'}>
          {label}
        </Typography>
      )}
      <Box ref={containerRef}>
        <TextField
          variant="outlined"
          fullWidth
          sx={{ width: inputWidth }}
          inputProps={{
            maxLength,
            style: {
              textAlign,
            },
          }}
          InputProps={{
            ...InputProps,
            style: {
              ...theme.components?.MuiTextField?.defaultProps?.InputProps?.style,
              backgroundColor,
            },
          }}
          {...cleanProps}
        />
      </Box>
    </Box>
  );
};

export default Input;
