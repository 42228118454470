import { Download } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Link, Stack, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { documents as documentsApi } from '../../api';
import theme, { sxHover } from '../../style/theme';
import { DocumentType, SalesOrder, SalesOrderShipment } from '../../types';
import Analytics from '../../utils/analytics';
import { getCurrentLanguage } from '../../utils/helper';

const style = {
  header: {
    padding: '0px 20px 30px 20px',
  },
  shipmentContainer: {
    backgroundColor: theme.palette.common.white,
    padding: '20px',
    borderBottom: '1px solid ' + theme.palette.grey[200],
  },
  downloadContainer: {
    paddingTop: {
      xs: 2,
      sm: 1,
    },
    paddingBottom: 1,
    justifyContent: {
      xs: 'flex-start',
      sm: 'flex-end',
    },
  },
};

type Props = {
  salesOrder: SalesOrder;
};

type DownloadingIdsProps = {
  credits: string[];
  sales: string[];
};

const SalesOrderShipments: React.FC<Props> = ({ salesOrder }) => {
  const { shipments } = salesOrder;
  const { palette } = useTheme();
  const { t } = useTranslation();
  const [downloadingIds, setDownloadingIds] = useState<DownloadingIdsProps>({ credits: [], sales: [] });

  const onCreditNotePress = async (shipmentId: string, creditNoteIds: string[]) => {
    setDownloadingIds((prev) => ({ ...prev, credits: [...prev.credits, shipmentId] }));
    try {
      await Promise.all(creditNoteIds.map((id) => documentsApi.download(id, DocumentType.CreditNote)));
      Analytics.sendEvent('download_credit_note', { credit_note_no: creditNoteIds.join(', ') });
    } finally {
      setDownloadingIds((prev) => ({ ...prev, credits: prev.credits.filter((c) => c !== shipmentId) }));
    }
  };

  const onInvoicePress = async (shipmentId: string, salesInvoiceId: string) => {
    setDownloadingIds((prev) => ({ ...prev, sales: [...prev.sales, shipmentId] }));
    try {
      await documentsApi.download(salesInvoiceId, DocumentType.SalesInvoice);
      Analytics.sendEvent('download_sales_invoice', { sales_invoice_no: salesInvoiceId });
    } finally {
      setDownloadingIds((prev) => ({ ...prev, sales: prev.sales.filter((s) => s !== shipmentId) }));
    }
  };

  const renderDownloadButton = (onClick: VoidFunction, text: string, isDownloading: boolean) => (
    <LoadingButton sx={sxHover} loading={isDownloading} startIcon={<Download />} onClick={onClick}>
      <Typography letterSpacing={0} variant="buttonSmall">
        {text}
      </Typography>
    </LoadingButton>
  );

  const renderTrackingUrl = ({ trackingNumber, trackingUrl }: SalesOrderShipment) => {
    const linkText = t('sales-orders:shipments.trackingNumber', { number: trackingNumber });
    if (!trackingNumber) {
      return null;
    } else if (trackingUrl) {
      return (
        <Link variant="link" target="_blank" href={trackingUrl}>
          {linkText}
        </Link>
      );
    } else {
      return <Typography variant="paragraphSmall">{linkText}</Typography>;
    }
  };

  const renderShipment = (salesOrderShipment: SalesOrderShipment, index: number) => {
    const { id, carrier, shippedDate, address, creditNoteIds, salesInvoiceId } = salesOrderShipment;

    return (
      <Grid key={index} container sx={style.shipmentContainer} justifyContent="space-between" alignItems="center">
        <Grid item xs={12} sm={7}>
          <Stack spacing={1}>
            <Typography variant="paragraphSmall">{carrier[getCurrentLanguage()]}</Typography>
            {renderTrackingUrl(salesOrderShipment)}
            <Typography variant="caption" color={palette.grey[300]}>
              {`${address} | ${shippedDate}`}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Stack direction="row" sx={style.downloadContainer} spacing={4}>
            {creditNoteIds?.length > 0 &&
              renderDownloadButton(
                () => onCreditNotePress(id, creditNoteIds),
                t('sales-orders:shipments.creditNote'),
                downloadingIds.credits.includes(id),
              )}
            {salesInvoiceId &&
              renderDownloadButton(
                () => onInvoicePress(id, salesInvoiceId),
                t('sales-orders:shipments.salesInvoice'),
                downloadingIds.sales.includes(id),
              )}
          </Stack>
        </Grid>
      </Grid>
    );
  };

  return (
    <Stack sx={style.header} spacing={3}>
      <Typography variant="h2">{t('sales-orders:shipments.title', { count: shipments.length })}</Typography>
      <Box>{shipments.map(renderShipment)}</Box>
    </Stack>
  );
};

export default SalesOrderShipments;
