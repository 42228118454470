import { Download, Info } from '@mui/icons-material';
import {
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { documents as documentsApi } from '../../api';
import { sxHover } from '../../style/theme';
import { DocumentType, Quotation, QuotationLine } from '../../types';
import { formatCurrency, getSum } from '../../utils/helper';
import ProductDrawer from '../Dashboard/ProductDrawer';
import SearchInput from '../FormControls/SearchInput';

const style = {
  header: {
    padding: '0px 20px 0px 20px',
    marginTop: -2,
  },
  headerTitle: {
    paddingTop: 3,
  },
};

type Props = {
  quotation: Quotation;
};

const QuotationLineDetails: React.FC<Props> = ({ quotation }) => {
  const { lineDetails } = quotation;
  const { palette } = useTheme();
  const { t } = useTranslation();
  const [data, setData] = useState<QuotationLine[]>([]);
  const [openedProduct, setOpenedProduct] = useState<QuotationLine | null>(null);
  const [downloadingLines, setDownloadingLines] = useState<number[]>([]);

  useEffect(() => setData(lineDetails), [lineDetails]);

  const onSearchTextChange = (keyword: string) => {
    const lowerKeyword = keyword.toLowerCase();
    const filterData = lineDetails.filter(
      (line) =>
        line.customerReference.toLowerCase().includes(lowerKeyword) ||
        line.serialNumber?.toLowerCase().includes(lowerKeyword) ||
        line.productCode.toLowerCase().includes(lowerKeyword),
    );
    setData(filterData);
  };

  const openProductDrawer = (lineDetail: QuotationLine) => setOpenedProduct(lineDetail);

  const closeProductDrawer = () => setOpenedProduct(null);

  const downloadDocuments = async ({ lineNumber, documentIds }: QuotationLine) => {
    setDownloadingLines((prev) => [...prev, lineNumber]);
    try {
      await documentsApi.download(documentIds, DocumentType.Document);
    } finally {
      setDownloadingLines((prev) => prev.filter((line) => line !== lineNumber));
    }
  };

  const isDownloading = (line: QuotationLine) => downloadingLines.includes(line.lineNumber);

  const renderProductDrawer = () => {
    if (!openedProduct) {
      return null;
    }

    return (
      <ProductDrawer
        isOpen={!!openedProduct}
        onClose={closeProductDrawer}
        productCode={openedProduct.productCode}
        description={openedProduct.description}
        options={openedProduct.options}
      />
    );
  };

  const renderTotal = () => (
    <Typography textAlign="end" variant="h2">
      {t('quotations:lineDetails.total', { total: formatCurrency(getSum(data, 'totalPrice')) })}
    </Typography>
  );

  const renderTable = () => (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t('quotations:lineDetails.lineNumber')}</TableCell>
            <TableCell>{t('quotations:lineDetails.productCode')}</TableCell>
            <TableCell>{t('quotations:lineDetails.customerReference')}</TableCell>
            <TableCell align="center">{t('common:product.quantity')}</TableCell>
            <TableCell align="right">{t('common:product.unitPrice')}</TableCell>
            <TableCell align="right">{t('common:product.totalPrice')}</TableCell>
            <TableCell align="right">{t('common:actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((line) => (
            <TableRow key={line.lineNumber}>
              <TableCell>{line.lineNumber}</TableCell>
              <TableCell>{line.productCode}</TableCell>
              <TableCell>{line.customerReference}</TableCell>
              <TableCell align="center">{line.quantity}</TableCell>
              <TableCell align="right">{formatCurrency(line.unitPrice)}</TableCell>
              <TableCell align="right">{formatCurrency(line.totalPrice)}</TableCell>
              <TableCell align="right">
                <Stack direction="row" display="flex" justifyContent="flex-end" spacing={2}>
                  {line.hasAttachments && !!line.documentIds?.length && (
                    <IconButton disabled={isDownloading(line)} onClick={() => downloadDocuments(line)} size="small">
                      {isDownloading(line) ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        <Download htmlColor={palette.grey[400]} fontSize="small" />
                      )}
                    </IconButton>
                  )}
                  <IconButton onClick={() => openProductDrawer(line)} size="small">
                    <Info sx={sxHover} htmlColor={palette.grey[400]} fontSize="small" />
                  </IconButton>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Stack spacing={2} sx={style.header}>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={4} lg={6} xl={8} sx={style.headerTitle}>
          <Typography variant="h2">{t('quotations:lineDetails.title')}</Typography>
        </Grid>
        <Grid item xs={12} sm={8} lg={6} xl={4} alignItems="center">
          <SearchInput
            variant="outlinedWhite"
            onClearText={() => onSearchTextChange('')}
            placeholder={t('quotations:lineDetails.searchPlaceholder')}
            onChangeText={onSearchTextChange}
          />
        </Grid>
      </Grid>
      {renderTable()}
      {renderTotal()}
      {renderProductDrawer()}
    </Stack>
  );
};

export default QuotationLineDetails;
