import { CheckCircle } from '@mui/icons-material';
import { Box, Card, CardActionArea, CardContent, Divider, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from '../../../style/theme';
import { Address } from '../../../types';
import BaseModal from '../../BaseModal';
import AddressCard from './AddressCard';

const style = {
  cardContainer: {
    paddingLeft: '25px',
    paddingRight: '25px',
    borderRadius: '10px',
  },
  card: {
    border: '1px solid transparent',
  },
  selectedCard: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: '10px',
    border: '1px solid ' + theme.palette.primary.main,
  },
  checkCard: { position: 'absolute', left: '10px', top: '10px' },
  divider: {
    marginTop: '20px',
    marginBottom: '20px',
  },
};

type Props = {
  isVisible: boolean;
  onConfirm: (addressId: string) => void;
  onCancel: () => void;
  addresses: Address[];
  currentAddressId?: string;
};

const SelectAddressModal: React.FC<Props> = (props) => {
  const { isVisible, onConfirm, onCancel, addresses, currentAddressId } = props;
  const { t } = useTranslation();
  const { palette } = useTheme();
  const [selectedAddressId, setSelectedAddressId] = useState<string | undefined>(currentAddressId);

  useEffect(() => {
    setSelectedAddressId(currentAddressId);
  }, [currentAddressId]);

  const onCancelClick = () => {
    setSelectedAddressId(currentAddressId);
    onCancel();
  };

  return (
    <BaseModal
      title={t('form:step2.selectAddressTitle')}
      confirmText={t('form:step2.selectAddressConfirm')}
      isVisible={isVisible}
      onConfirm={() => selectedAddressId && onConfirm(selectedAddressId)}
      onCancel={onCancelClick}
      width={{ xs: '95%', sm: 600 }}
    >
      <Box>
        {addresses.map((a, i) => {
          const isSelected = selectedAddressId === a.addressId;
          return (
            <Box key={a.addressId + i.toString()}>
              <Card sx={isSelected ? style.selectedCard : style.card}>
                <CardActionArea
                  sx={style.cardContainer}
                  component="div"
                  onClick={() => setSelectedAddressId(a.addressId)}
                >
                  <CardContent>
                    {isSelected && <CheckCircle sx={style.checkCard} htmlColor={palette.primary.main} />}
                    <AddressCard address={a} />
                  </CardContent>
                </CardActionArea>
              </Card>
              <Divider sx={style.divider} />
            </Box>
          );
        })}
      </Box>
    </BaseModal>
  );
};

export default SelectAddressModal;
