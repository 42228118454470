import { yupResolver } from '@hookform/resolvers/yup';
import { Add, Search } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  CircularProgress,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { products as productsApi } from '../../../api';
import theme from '../../../style/theme';
import { typography } from '../../../style/typography';
import {
  BaseProduct,
  CreationForm,
  CreationLineDetails,
  ProductFamilies,
  ProductFamilyDetails,
  SearchProductsParams,
  SearchProducts as SearchProductsType,
} from '../../../types';
import { getLineDetailsInitialValues, getSearchProductsParamsInitialValues } from '../../../utils/form';
import { getCurrentLanguage, getDomain } from '../../../utils/helper';
import InputHookForm from '../../FormControls/HookForm/InputHookForm';
import SearchSelectHookForm from '../../FormControls/HookForm/SearchSelectHookForm';

const style = {
  divider: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  button: {
    height: '48px',
    ...typography.buttonMedium,
    paddingLeft: '25px',
    paddingRight: '25px',
  },
  tableHead: {
    backgroundColor: theme.palette.grey[100],
    border: 0,
  },
  familyDetailLoading: {
    marginBottom: '13px !important',
  },
  power: {
    width: 100,
  },
  searchSelect: {
    flex: 1,
    maxWidth: '100%',
  }
};

type Props = {
  onProductAdded: (product: CreationLineDetails) => void;
};

const SearchProducts = ({ onProductAdded }: Props) => {
  const { t } = useTranslation();
  const watchPlant = useWatch<CreationForm, 'plant'>({ name: 'plant' });
  const watchCustomerAccountId = useWatch<CreationForm, 'customerAccountId'>({ name: 'customerAccountId' });
  const watchRequiredArrivalDate = useWatch<CreationForm, 'requiredArrivalDate'>({ name: 'requiredArrivalDate' });
  const [searchType, setSearchType] = useState<'code' | 'family'>('family');
  const [products, setProducts] = useState<SearchProductsType['products']>([]);
  const [families, setFamilies] = useState<ProductFamilies['productFamilies']>([]);
  const [isLoadingFamilyDetails, setLoadingFamilyDetails] = useState(false);
  const [familyDetails, setFamilyDetails] = useState<ProductFamilyDetails | null>(null);
  const [isLoadingProducts, setLoadingProducts] = useState(false);
  const [addLoadingProductId, setAddLoadingProductId] = useState<null | string>(null);

  const validationSchemaCode = Yup.object({
    code: Yup.string().required(),
  });

  const validationSchemaFamily = Yup.object({
    familyId: Yup.string().required(),
  });

  const { reset, control, handleSubmit } = useForm({
    resolver: yupResolver(searchType === 'code' ? validationSchemaCode : validationSchemaFamily),
    defaultValues: getSearchProductsParamsInitialValues(),
  });

  useEffect(() => {
    (async () => {
      const result = (await productsApi.getProductFamilies(watchPlant)).data;
      setFamilies(result.productFamilies);
    })();
  }, [watchPlant]);

  const onSearch = async (params: SearchProductsParams) => {
    try {
      setLoadingProducts(true);
      const searchTypeParams = searchType === 'code' ? { code: params.code } : { ...params, code: '' };
      const result = (
        await productsApi.searchProducts({
          ...searchTypeParams,
          plantId: watchPlant,
          customerAccountId: watchCustomerAccountId ?? '',
        })
      ).data;
      setProducts(result.products);
    } finally {
      setLoadingProducts(false);
    }
  };

  const onFamilyChange = async (familyId: string) => {
    try {
      if (!familyId) {
        setFamilyDetails(null);
        return;
      }
      setLoadingFamilyDetails(true);
      const result = (await productsApi.getProductFamilyDetails(familyId)).data;
      setFamilyDetails(result);
    } finally {
      reset({ ...getSearchProductsParamsInitialValues(), familyId });
      setLoadingFamilyDetails(false);
    }
  };

  const onProductAddClick = async ({ id, code, description }: BaseProduct) => {
    try {
      setAddLoadingProductId(id);
      const options = (await productsApi.getProductOptions(id, watchCustomerAccountId)).data.options ?? [];
      onProductAdded({
        ...getLineDetailsInitialValues(),
        productId: id,
        productCode: code,
        productDescription: description,
        requiredArrivalDate: watchRequiredArrivalDate,
        options,
      });
    } finally {
      setAddLoadingProductId(null);
    }
  };

  const renderSearchButton = () => (
    <LoadingButton
      loading={isLoadingProducts}
      disabled={isLoadingProducts}
      sx={style.button}
      startIcon={<Search />}
      variant="contained"
      type="submit"
    >
      {t('form:step3.search.action')}
    </LoadingButton>
  );

  const renderSearchCode = () => (
    <InputHookForm
      hideError
      control={control}
      label={t('form:step3.search.code.label', { brand: getDomain() })}
      name="code"
      autoFocus
    />
  );

  const renderSearchFamily = () => (
    <>
      <SearchSelectHookForm
        hideError
        control={control}
        label={t('form:step3.search.family.productFamily')}
        options={families.map((f) => ({ label: f.code, value: f.id }))}
        onChange={onFamilyChange}
        name="familyId"
        sx={style.searchSelect}
        inputWidth="100%"
      />
      {renderFamilyDetails()}
    </>
  );

  const renderFamilyDetailsSelect = (
    familyDetailsKey: keyof ProductFamilyDetails,
    searchParamKey: keyof SearchProductsParams,
    linkedElement?: React.ReactNode,
  ) =>
    familyDetails &&
    familyDetails[familyDetailsKey]?.length > 0 && (
      <>
        {linkedElement}
        <SearchSelectHookForm
          hideError
          control={control}
          label={t(`form:step3.search.family.${familyDetailsKey}`)}
          options={familyDetails[familyDetailsKey].map((d) => ({ label: d.value, value: d.id }))}
          name={searchParamKey}
        />
      </>
    );

  const renderFamilyDetails = () =>
    isLoadingFamilyDetails ? (
      <CircularProgress size={20} sx={style.familyDetailLoading} />
    ) : (
      <>
        {renderFamilyDetailsSelect('lineVoltages', 'lineVoltageId')}
        {renderFamilyDetailsSelect('dcVoltages', 'dcVoltageId')}
        {renderFamilyDetailsSelect(
          'powerUnits',
          'powerUnitId',
          <Box sx={style.power}>
            <InputHookForm
              hideError
              control={control}
              label={t('form:step3.search.family.powers')}
              name="power"
              type="number"
              allowUndefinedNumber
            />
          </Box>,
        )}
        {renderFamilyDetailsSelect('frequencies', 'frequencyId')}
        {renderFamilyDetailsSelect('startStopOptions', 'startStopOptionId')}
        {renderFamilyDetailsSelect('shortCircuitRatings', 'shortCircuitRatingId')}
        {renderFamilyDetailsSelect('horsepowerRatings', 'horsepowerRatingId')}
        {renderFamilyDetailsSelect('phaseVoltages', 'phaseVoltageId')}
        {renderFamilyDetailsSelect('batteryTypes', 'batteryTypeId')}
        {renderFamilyDetailsSelect('batteryVoltages', 'batteryVoltageId')}
      </>
    );

  const renderSearchResults = () => (
    <Stack spacing={2}>
      <Typography variant="h3">{t('form:step3.search.results', { count: products.length })}</Typography>

      <TableContainer>
        <Table>
          <TableHead sx={style.tableHead}>
            <TableRow>
              <TableCell>
                <Typography variant="paragraphSmallBold">{t('common:product.code')}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="paragraphSmallBold">{t('common:product.description')}</Typography>
              </TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow key={product.id}>
                <TableCell>{product.code}</TableCell>
                <TableCell>{product.description[getCurrentLanguage()]}</TableCell>
                <TableCell align="right">
                  <LoadingButton
                    onClick={() => onProductAddClick(product)}
                    disabled={!!addLoadingProductId}
                    sx={style.button}
                    startIcon={<Add />}
                    loading={addLoadingProductId === product.id}
                    loadingIndicator={<CircularProgress color="primary" size={16} />}
                    variant="contained"
                  >
                    {t('common:add')}
                  </LoadingButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );

  return (
    <Box>
      <Stack spacing={3}>
        <Box>
          <RadioGroup row value={searchType} onChange={(ev) => setSearchType(ev.target.value as any)}>
            <FormControlLabel
              value="family"
              control={<Radio />}
              label={t('form:step3.search.family.radio') as string}
            />
            <FormControlLabel value="code" control={<Radio />} label={t('form:step3.search.code.radio') as string} />
          </RadioGroup>
          <form onSubmit={handleSubmit((d) => onSearch(d))}>
            <Stack
              flexWrap={searchType === 'code' ? 'nowrap' : 'wrap'}
              direction="row"
              rowGap={1}
              spacing={1.5}
              display="flex"
              alignItems="flex-end"
            >
              {searchType === 'code' ? renderSearchCode() : renderSearchFamily()}
              {renderSearchButton()}
            </Stack>
          </form>
        </Box>
        <Divider />

        {renderSearchResults()}
      </Stack>
    </Box>
  );
};
export default SearchProducts;
