import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './hooks/useAuth';
import './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { setFavicon } from './utils/helper';

ReactDOM.render(
  <AuthProvider>
    <BrowserRouter>
      <React.Suspense fallback="">
        <App />
      </React.Suspense>
    </BrowserRouter>
  </AuthProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
setFavicon();
